import React from 'react';
import { List, Spoiler, ThemeIcon } from '@mantine/core';
import { CircleCheck } from 'tabler-icons-react';
import Layout from '../../components/Layout';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import Tabs from '../../components/Tabs';
import team1 from '../../assets/images/team-1-1.jpg';

const Restorative = () => {
  const services = [
    {
      name: '	Root canal treatment ',
    },
    {
      name: 'Pulp therapy',
    },
    { name: '	Apicectomy' },
    {
      name: '	Composite , glass ionomer and amalgam fillings ',
    },

    {
      name: '	Post retained crowms',
    },
    {
      name: '	Ceramic crowns',
    },
    { name: 'Implant retained restorations' },
    { name: ' metal- ceramic bridges' },
    { name: 'Partial dentures' },
    { name: 'Complete dentures' },
    { name: 'Implant retained overdenture ' },
    { name: 'Obturators' },
  ];

  const technologists = [
    { name: 'Mrs F. Daras' },
    { name: 'Mr G.  Ezembah' },
    { name: 'Mrs Ifeoma Ugwu' },
  ];
  const administrative = [
    { name: 'Ms Bola Adeniran' },
    { name: 'Mrs S Showemimo' },
  ];

  return (
    <Layout pageTitle='Department of Restorative Dentistry | LUTH'>
      <NavOne />
      <PageHeader title='Department of Restorative Dentistry' />
      <section className='course-details'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='course-details__content'>
                <div className='course-details__top'>
                  <div className='course-details__top-left'>
                    <h2 className='course-details__title'>
                      Department of Restorative Dentistry
                    </h2>
                  </div>
                </div>
                <Tabs>
                  <div label='Overview'>
                    <Spoiler
                      maxHeight={300}
                      hideLabel='Hide'
                      showLabel='Show More'
                    >
                      <p className='course-details__tab-text'>
                        The department currently has seven honorary consultants
                        to the teaching hospital and works closely with the
                        hospital management to ensure quality restorative dental
                        care to our patients.It consists of two major units and
                        several sub-specialties.
                        <br />
                        1. <b>Conservative Dentistry unit</b> which comprises of
                        Preclinical Operative Techniques Advanced Operative
                        Techniques, Conservations, Endodontics, Science of
                        Dental Materials and Aesthetic Dentistry, Implant
                        prosthodontics
                        <br />
                        2. <b>Prosthetic Dentistry Unit</b> which comprises of
                        Preclinical Prosthetic techniques, Partial and Complete
                        Denture techniques and Advanced Prosthodontics, Implant
                        prosthodontics
                      </p>
                    </Spoiler>
                  </div>
                  <div label='Services'>
                    <List
                      spacing='xs'
                      size='sm'
                      center
                      icon={
                        <ThemeIcon color='teal' size={24} radius='xl'>
                          <CircleCheck size={16} />
                        </ThemeIcon>
                      }
                    >
                      {services.map((data) => (
                        <List.Item key={data.name}>{data.name}</List.Item>
                      ))}
                    </List>
                  </div>
                  <div label='Staff'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Staff Details</h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. P. A. Akeredolu </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BChD FMCDS, FWACS
                            <br />
                            <span className='detail-head'>Designation:</span>
                            Professor
                            <br />
                            <span className='detail-head'>Specialty:</span>
                            Prosthodontics
                            <br />
                            <span className='detail-head'>Research Focus:</span>
                            Removable prosthodontics, Maxillofacial prosthesis                            
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. D. C. Umesi </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BDS, FWACS, MPH, MPA
                            <br />
                            <span className='detail-head'>Designation:</span>
                            Associate Professor
                            <br />
                            <span className='detail-head'>Specialty:</span>
                            Conservative Dentistry
                            <br />
                            <span className='detail-head'>Research Focus:</span>
                            Restorative dentistry, Endodontics, Adhesives,
                            Aesthetic Dentistry, Science of Dental materials,
                            Public health, Medical Education                           
                          </p>
                        </div>
                      </div>

                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Y.O. Ajayi </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BDS, FWACS, MSc Oral Implantology(Frankfurt),
                            <br />
                            <span className='detail-head'>Designation:</span>
                            Associate Professor/Acting Head of Department
                            <br />
                            <span className='detail-head'>Specialty:</span>
                            Conservative Dentistry
                            <br />
                            <span className='detail-head'>Research Focus:</span>
                            Implant Prosthodontics, Science of Dental materials,
                            Tissue engineering in Implantology <br />                            
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. O. H. Oderinu </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BDS, FMCDS, MPH, MSc Medical Education(Cardiff)
                            <br />
                            <span className='detail-head'>Designation:</span>
                            Associate Professor
                            <br />
                            <span className='detail-head'>Specialty:</span>
                            Conservative Dentistry
                            <br />
                            <span className='detail-head'>Research Focus:</span>
                            Endodontics, Restorative materials, Medical
                            Education 
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. B. O. Akinboboye </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BDS, FWACS, MSc
                            <br />
                            <span className='detail-head'>Designation:</span>
                            Associate Professor
                            <br />
                            <span className='detail-head'>Specialty:</span>
                            Prosthodontics
                            <br />
                            <span className='detail-head'>Research Focus:</span>
                            Removable prosthodontics, Gerodontology 
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. I. C. Adegbulugbe </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BDS, FWACS
                            <br />
                            <span className='detail-head'>Designation:</span>
                            Senior Lecturer
                            <br />
                            <span className='detail-head'>Specialty:</span>
                            Conservative Dentistry
                            <br />
                            <span className='detail-head'>Research Focus:</span>
                            Endodontics, Restorative Materials, Tooth wear,
                            Operative techniques 
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. O. A. Oremosu </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BDS, FMCDS,MPH
                            <br />
                            <span className='detail-head'>Designation:</span>
                            Senior Lecturer
                            <br />
                            <span className='detail-head'>Specialty:</span>
                            Prosthodontics
                            <br />
                            <span className='detail-head'>Research Focus:</span>
                            Removable prosthodontics 
                          </p>
                        </div>
                      </div>
                      <h2 className='blog-one__title' style={{ marginTop: 20 }}>
                        Technologists
                      </h2>
                      <List
                        spacing='xs'
                        size='sm'
                        center
                        icon={
                          <ThemeIcon color='teal' size={24} radius='xl'>
                            <CircleCheck size={16} />
                          </ThemeIcon>
                        }
                      >
                        {technologists.map((data) => (
                          <List.Item key={data.name}>{data.name}</List.Item>
                        ))}
                      </List>
                      <h2 className='blog-one__title' style={{ marginTop: 20 }}>
                        Administrative
                      </h2>
                      <List
                        spacing='xs'
                        size='sm'
                        center
                        icon={
                          <ThemeIcon color='teal' size={24} radius='xl'>
                            <CircleCheck size={16} />
                          </ThemeIcon>
                        }
                      >
                        {administrative.map((data) => (
                          <List.Item key={data.name}>{data.name}</List.Item>
                        ))}
                      </List>
                    </React.Fragment>
                  </div>
                  <div label='Contact'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Departmental Email</h2>
                      <p className='course-details__tab-text'>
                        <a href='mailto:restodentistry@luth.gov.ng'>                        
                        restodentistry@luth.gov.ng
                      </a>
                      </p>
                    </React.Fragment>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default Restorative;
